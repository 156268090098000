import { Service, ServicesServer } from '@wix/ambassador-services-server/http';
import { AmbassadorBaseUrl } from '../../../../../constants/ambassador';

export async function getWixBookingsServices(
  authorization: string,
): Promise<Service[]> {
  try {
    const bookingsService = ServicesServer(
      AmbassadorBaseUrl.BookingsService,
    ).ServicesService()({
      authorization,
    });

    const { services } = await bookingsService.list({});
    if (!services) {
      return [];
    }

    return services;
  } catch (e) {
    // TODO: add sentry
    console.error(e);
    return [];
  }
}
