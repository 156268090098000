import {
  BatchUpdateResponse,
  ServicesServer,
} from '@wix/ambassador-services-server/http';
import { AmbassadorBaseUrl } from '../../../../../constants/ambassador';

export async function setWixBookingsServiceInfoMediaMainImageAltText(
  authorization: string,
  id: string,
  altText: string,
): Promise<BatchUpdateResponse> {
  try {
    const bookingsService = ServicesServer(
      AmbassadorBaseUrl.BookingsService,
    ).ServicesService()({
      authorization,
    });

    return bookingsService.batchUpdate({
      fieldMask: ['info.media.mainMedia.image.altText'],
      services: [
        {
          id,
          info: {
            media: {
              mainMedia: {
                image: {
                  altText,
                },
              },
            },
          },
        },
      ],
    });
  } catch (e) {
    // TODO: add sentry
    console.error(e);
    return {};
  }
}
