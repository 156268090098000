import {
  BatchUpdateResponse,
  ServicesServer,
} from '@wix/ambassador-services-server/http';
import { MediaItem } from '@wix/ambassador-services-server/types';
import { AmbassadorBaseUrl } from '../../../../../constants/ambassador';

export async function setWixBookingsServiceInfoMediaImages(
  authorization: string,
  id: string,
  items: MediaItem[],
): Promise<BatchUpdateResponse> {
  try {
    const bookingsService = ServicesServer(
      AmbassadorBaseUrl.BookingsService,
    ).ServicesService()({
      authorization,
    });

    return bookingsService.batchUpdate({
      fieldMask: ['info.media.items'],
      services: [
        {
          id,
          info: {
            media: {
              items,
            },
          },
        },
      ],
    });
  } catch (e) {
    // TODO: add sentry
    console.error(e);
    return {};
  }
}
