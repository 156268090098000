import { Service, ServicesServer } from '@wix/ambassador-services-server/http';
import { AmbassadorBaseUrl } from '../../../../../constants/ambassador';

export async function getWixBookingsServiceById(
  authorization: string,
  id: string,
): Promise<Service | undefined> {
  try {
    const bookingsService = ServicesServer(
      AmbassadorBaseUrl.BookingsService,
    ).ServicesService()({
      authorization,
    });

    const { service } = await bookingsService.get({ id });
    if (!service) {
      return;
    }

    return service;
  } catch (e) {
    // TODO: add sentry
    console.error(e);
    return;
  }
}
